
import { defineComponent, reactive, ref, onMounted } from 'vue'
export default defineComponent({
  props: {
    checkboxesValues: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const cargoSpace = sessionStorage.getItem('cargo_space')
      ? ref(JSON.parse(sessionStorage.getItem('cargo_space') || ''))
      : ref(100)
    onMounted(() => {
      sessionStorage.setItem('cargo_space', JSON.stringify(cargoSpace.value))
    })
    const description = ref('_wizard.step6.description')
    const suitcases = reactive([
      {
        id: 1,
        image: require('@/assets/suitcase.svg'),
        isSelected: true,
        imageDisabled: require('@/assets/suitcase_disabled.svg'),
        cargoSpace: 100,
        description: '_wizard.step6.description'
      },
      {
        id: 2,
        image: require('@/assets/suitcase.svg'),
        isSelected: false,
        imageDisabled: require('@/assets/suitcase_disabled.svg'),
        cargoSpace: 200,
        description: '_wizard.step6.description'
      },
      {
        id: 3,
        image: require('@/assets/suitcase.svg'),
        isSelected: false,
        imageDisabled: require('@/assets/suitcase_disabled.svg'),
        cargoSpace: 300,
        description: '_wizard.step6.description'
      },
      {
        id: 4,
        image: require('@/assets/suitcase.svg'),
        isSelected: false,
        imageDisabled: require('@/assets/suitcase_disabled.svg'),
        cargoSpace: 400,
        description: '_wizard.step6.description'
      },
      {
        id: 5,
        image: require('@/assets/suitcase.svg'),
        isSelected: false,
        imageDisabled: require('@/assets/suitcase_disabled.svg'),
        cargoSpace: 500,
        description: '_wizard.step6.description'
      }
    ])
    const getSelectedValue = (storedStep) => {
      suitcases.forEach(function (item) {
        item.isSelected = false
      })
      const storedStepValue = JSON.parse(sessionStorage.getItem(`${storedStep}`) || '{}')
      for (let i = 0; i < suitcases.length; i++) {
        if (storedStepValue >= suitcases[i].cargoSpace) {
          suitcases[i].isSelected = true
        }
      }
      return suitcases
    }
    const getSuitCases = sessionStorage.getItem('cargo_space') ? getSelectedValue('cargo_space') : suitcases
    const changeRating = (item, type) => {
      suitcases.forEach(function (item) {
        item.isSelected = false
      })
      if (type === 'click') {
        cargoSpace.value = item.cargoSpace
        description.value = item.description
        for (let i = 0; i < suitcases.length; i++) {
          if (item.id >= suitcases[i].id) {
            suitcases[i].isSelected = true
            sessionStorage.setItem('cargo_space', JSON.stringify(item.cargoSpace))
          }
        }
      }
      if (type === 'hover') {
        for (let i = 0; i < suitcases.length; i++) {
          if (item.id >= suitcases[i].id) {
            suitcases[i].isSelected = true
          }
        }
      }
      if (type === 'mouseout') {
        for (let i = 0; i < suitcases.length; i++) {
          if (cargoSpace.value >= suitcases[i].cargoSpace) {
            suitcases[i].isSelected = true
          }
        }
      }
    }
    return { cargoSpace, description, suitcases, changeRating, getSuitCases }
  }
})
