
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'RangeSlider',
  props: {
    step: {
      type: Number,
      required: true
    }
  }
})
