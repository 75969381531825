
import { defineComponent, reactive } from 'vue'
import CustomSlider from '@/components/UI/CustomSlider.vue'
export default defineComponent({
  components: {
    CustomSlider
  },
  setup(props, { emit }) {
    const getSelectedBudget = reactive({value: sessionStorage.getItem('budget')
      ? JSON.parse(sessionStorage.getItem('budget') || '')
      : [0, 2500] })
    const selectedBudget = (budget) => {
      getSelectedBudget.value = budget
      emit('selectedBudget', budget)
    }
    return { selectedBudget, getSelectedBudget }
  }
})
