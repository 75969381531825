
import { defineComponent, ref } from 'vue'
import Popup from '@/components/Popup.vue'
import checkUserSettings from '@/mixins/checkUserSettings'
import wizard from '../components/Step1/wizard.vue'
export default defineComponent({
  components: {
    wizard,
    Popup
  },
  setup() {
    const componentKey = ref(0)
    const { displayUserSettingPopup, goToAccount } = checkUserSettings()
    const clickPrev = () => {
      componentKey.value += 1
    }
    return { clickPrev, componentKey, displayUserSettingPopup, goToAccount }
  }
})
